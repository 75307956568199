
import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  padding: 30px;
  overflow: hidden;
`;
